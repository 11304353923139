/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
import { memo, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { ThemeGlobal } from '@styles/theme-global';
import { convertScriptChats, isServer } from '@utils/helper';
import { useGoogleAnalytics } from '@utils/hooks/useGoogleAnalytics';
import { useLeadFormCapture } from '@utils/hooks/useLeadFormCapture';
import { HomeWrapper } from './styled';
import {
  selectSceneSelected,
  selectThemeConfigGlobal,
  selectTitleProject,
  selectPopupConfigGlobal,
  selectConfigProtectPassword,
  selectDetailProject,
  selectScriptChats,
  selectListCallToAction,
  selectCallToActionConfigGlobal,
} from './selectors';
import { useCheckPasswordTour } from './hook/useCheckPasswordTour';
import useGeneralFunction from './hook/useGeneralFunction';

const ThemeWall = dynamic(() => import('@containers/ThemeWall'));
const ThemeSolid = dynamic(() => import('@containers/ThemeSolid'));
const ThemeDefault = dynamic(() => import('@containers/ThemeDefault'));
const ThemeBlank = dynamic(() => import('@containers/ThemeBlank'));
const ThemeBaseKrpano = dynamic(() => import('@containers/ThemeBaseKrpano'));

const ProtectPassword = dynamic(() => import('@components/ProtectPassword'));
const PopupIntro = dynamic(
  () => import('@containers/Home/components/PopupIntro'),
  { ssr: false },
);
const ActionHotspot = dynamic(() => import('./components/ActionHotspot'), {
  ssr: false,
});
const Collaboration = dynamic(() => import('./components/Collaboration'), {
  ssr: false,
});
const DialogFormCapture = dynamic(
  () => import('@components/DialogFormCapture'),
  { ssr: false },
);
const CallToActions = dynamic(() => import('@components/CallToActions'));
const DangerouslySetHtmlContent = dynamic(
  () => import('@components/DangerouslySetHtmlContent'),
);

interface Props {
  isPublic?: boolean;
  isCollaborationMode?: boolean;
  // NOTE: is case custom domain => not save scene on url
  isPreventChangeUrl?: boolean;
}

function Home({
  isPublic,
  isCollaborationMode = false,
  isPreventChangeUrl = false,
}: Props) {
  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>(null!);

  const scriptChats = useSelector(selectScriptChats);
  const titleProject = useSelector(selectTitleProject);
  const detailProject = useSelector(selectDetailProject);
  const sceneSelected = useSelector(selectSceneSelected);
  const popupConfig = useSelector(selectPopupConfigGlobal);
  const themeConfig = useSelector(selectThemeConfigGlobal);
  const listCallToAction = useSelector(selectListCallToAction);
  const callToActionConfigGlobal = useSelector(selectCallToActionConfigGlobal);

  const configProtectPassword = useSelector(selectConfigProtectPassword);

  const { onUpdatePageViewByUrl } = useGoogleAnalytics();
  const { nextScene, onNextScene } = useGeneralFunction();

  const { isOpenGuardPassword, onCheckGuardPassword } = useCheckPasswordTour({
    detailProject,
    configProtectPassword,
  });

  const {
    onCloseFormCapture,
    formCaptureSelected,
    isVisibleFormCapture,
    formCaptureConfigGlobal,
    formCaptureConfigSelected,
    onCheckShowFormCaptureOnScene,
    onCheckShowFormCaptureOnProject,
  } = useLeadFormCapture();

  const scriptsChatConverted = useMemo(
    () =>
      scriptChats && scriptChats?.length
        ? convertScriptChats(scriptChats)
        : undefined,
    [scriptChats],
  );

  useEffect(() => {
    if (sceneSelected && !isPreventChangeUrl) {
      if (!isCollaborationMode) {
        const url = isPublic
          ? `${process.env.NEXT_PUBLIC_BASE_URL_PUBLIC}/${router.query.tour}/${sceneSelected.id}`
          : `${process.env.NEXT_PUBLIC_BASE_URL_PREVIEW}?tour=${router.query.tour}&scene=${sceneSelected.id}`;
        router.push(url, undefined, { shallow: true });
      } else {
        const url = `${process.env.NEXT_PUBLIC_BASE_URL_COLLABORATION}?tour=${router.query.tour}&scene=${sceneSelected.id}`;
        if (!isServer) window.history.pushState(null, null, url);
      }
      onUpdatePageViewByUrl();
    } else if (sceneSelected && isPreventChangeUrl)
      window.history.pushState(
        null,
        null,
        `${process.env.NEXT_PUBLIC_SUBFOLDER || ''}/?scene=${sceneSelected.id}`,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneSelected]);

  // CHECK FORM CAPTURE TO SHOW
  useEffect(() => {
    if (isVisibleFormCapture === null && detailProject?.id)
      onCheckShowFormCaptureOnProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailProject?.id]);

  useEffect(() => {
    if (isVisibleFormCapture === false && sceneSelected)
      onCheckShowFormCaptureOnScene(sceneSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleFormCapture, sceneSelected]);

  return isOpenGuardPassword ? (
    <ProtectPassword
      scene={sceneSelected}
      password={configProtectPassword?.password}
      onSubmit={onCheckGuardPassword}
    />
  ) : (
    <>
      <HomeWrapper ref={containerRef}>
        {isCollaborationMode && <Collaboration />}
        <div className="mainContent">
          {!!titleProject && (
            <>
              {themeConfig?.selected === 'blank' && (
                <ThemeBlank
                  onChangeScene={onNextScene}
                  onNextScene={onNextScene.bind(this, nextScene)}
                />
              )}
              {themeConfig?.selected === 'wall' && (
                <ThemeWall
                  onChangeScene={onNextScene}
                  onNextScene={onNextScene.bind(this, nextScene)}
                />
              )}
              {themeConfig?.selected === 'basekrpano' && (
                <ThemeBaseKrpano
                  onChangeScene={onNextScene}
                  onNextScene={onNextScene.bind(this, nextScene)}
                />
              )}
              {themeConfig?.selected === 'custom1' && (
                <ThemeSolid
                  onChangeScene={onNextScene}
                  onNextScene={onNextScene.bind(this, nextScene)}
                />
              )}
              {(!themeConfig?.selected ||
                themeConfig?.selected === 'default') && (
                <ThemeDefault
                  onChangeScene={onNextScene}
                  onNextScene={onNextScene.bind(this, nextScene)}
                />
              )}
            </>
          )}
          <ActionHotspot />
          {!!listCallToAction.length && (
            <CallToActions
              listCallToAction={listCallToAction}
              callToActionConfig={callToActionConfigGlobal}
            />
          )}
          <PopupIntro />

          {!!formCaptureSelected?.id && (
            <DialogFormCapture
              onCancel={onCloseFormCapture}
              visible={isVisibleFormCapture}
              formCaptureSelected={formCaptureSelected}
              formCaptureConfigGlobal={formCaptureConfigGlobal}
              formCaptureConfigSelected={formCaptureConfigSelected}
            />
          )}
        </div>
        <ThemeGlobal
          selected={themeConfig?.selected || 'default'}
          primaryColor={themeConfig?.config?.primaryColor}
          secondaryColor={
            themeConfig?.selected === 'custom1'
              ? themeConfig?.config?.secondaryColor
              : themeConfig?.config?.primaryColor
          }
          textColor={themeConfig?.config?.textColor}
          popupBgColor={popupConfig?.bgColor}
          popupTextColor={popupConfig?.textColor}
        />
      </HomeWrapper>

      {/* NOTE: Load scriptChats */}
      {!!scriptsChatConverted?.htmls && (
        <DangerouslySetHtmlContent html={scriptsChatConverted?.htmls} />
      )}
      {!!scriptsChatConverted?.scripts && (
        <DangerouslySetHtmlContent html={scriptsChatConverted?.scripts} />
      )}
    </>
  );
}

export default memo(Home);
